import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Victoria Geiborg",
    description:
      "It's absolutely incredible, I never thought it turned out this good.",
  },
  {
    id: 2,
    image: Image2,
    title: "Trond Solvold",
    description:
      "Vanguard solutions as Rasmus is a recent media technology and engineering graduate. Together with other global players in the solutions offered, I am very much looking forward to our future projects.",
  },
  {
    id: 3,
    image: Image3,
    title: "Fredrik Josefson",
    description:
      "A really good job, all aspects of the project were followed step by step and with good results.",
  },
];
