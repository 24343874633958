import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Rasmus Elmersson</h1>

        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>

          <li>
            <a href="#portfolio" className="footer__link">
              Projects
            </a>
          </li>

          <li>
            <a href="#testimonials" className="footer__link">
              Testimonials
            </a>
          </li>
        </ul>

        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/rasmus-elmersson-79a161174/"
            className="home__social-icon"
            target="_blank"
            rel='noreferrer'
          >
            <i className="uil uil-linkedin"></i>
          </a>

          <a
            href="https://github.com/elmersson"
            className="home__social-icon"
            target="_blank"
            rel='noreferrer'
          >
            <i className="uil uil-github"></i>
          </a>
        </div>

        <span className="footer__copy">
          &#169; Rasmus Elmersson. All rigths reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
